
import { Options, Vue } from "vue-class-component";
import { getBillLast, getInfo } from "@/api/goods";
import { showToast } from "vant";
import axios from 'axios';

declare const window: any;
export default class Home extends Vue {
  //user = { remark: "", userName: "" };
  availHeight = 800;
  image = "https://xiaolianxiaopiao.obs.cn-north-4.myhuaweicloud.com/admin/4b48094e119947a7a9d8767f7be9ec64.png";
  billImage = "";
  qr = "";
  amount = "0.00";
  mac: any;
  mess = "";
  // webSock = new WebSocket("ws://192.168.0.100:8107/notice/C8:9B:AD:5C:15:39");
  webSock: any;
  lockReconnect = false;
  isLog = true;
  isNoData = true;
  isPaySuccess = false;
  isSequencePrint = "2";
  sendTime: any;
  async mounted() {
    window.getMac = function (data: string) {
      window.mac = data;
    };
    window.active = function () {
      // this.initWebSocket();
    };
    this.initWebSocket();
    this.getConfiguration();
  }

  initWebSocket() {
    // window.mac = 'e5304a281f107507';
    if (!window.mac) {
      setTimeout(() => {
      this.initWebSocket();

    }, 3000);
    return;
    }
    //  let websocketUrl = `ws://192.168.0.103:8107/notice/${window.mac}`; // 本地
     let websocketUrl = `ws://117.78.9.200:8108/notice/${window.mac}`; // 测试

    this.webSock = new WebSocket(websocketUrl);
    this.webSock.onopen = this.webSocketOnOpen;
    this.webSock.onerror = this.webScoketOnError;
    this.webSock.onmessage = this.webSocketOnMessage;
    this.webSock.onclose = this.webSocketClose;
    // setTimeout(() => {
    //   this.initWebSocket();
    //
    // }, 300000);
  }

  webSocketOnOpen() {
    // alert("websocket连接成功");
    showToast("socket连接成功");
    console.log("websocket连接成功");

    this.sendText();
  }
  webSocketOnMessage(e: any) {
    console.log("websocket接收数据");
    console.log(e);
    var map = eval("(" + e.data + ")");
    // alert("收到" + map.noticeInfo.image);
    console.log(map + "data");
    if (map.noticeType == 1) {
      this.isNoData = false;
      const img = map.noticeInfo.image;
      this.image = img;
      this.billImage = map.noticeInfo.imageFile;
      this.qr = map.noticeInfo.qrCode;
      this.amount = map.noticeInfo.amount;
      this.isPaySuccess = false;
    } else if (map.noticeType == 3) {
      //打印小票成功后
      this.printTicket(map.noticeInfo);

    } else if (map.noticeType == 2) {
      //调用sendHttp发送返回来的数据，支付成功，付款二维码加遮罩层文字
      this.isPaySuccess = true;
      this.sendHttp(map.noticeInfo);
    //   setTimeout(() => {
    //   this.isNoData = true
    // }, 3000);
    }else if(map.noticeType == 4){
      clearInterval(this.sendTime);
      this.sendText();
    }
  }
  webSocketClose(e: any) {
    console.log("websocket断开连接");
    console.log(e);
    if(this.sendTime){
      clearInterval(this.sendTime);
    }
    this.lockReconnect = false;
    this.reconnect();
  }
  webScoketOnError(e: any) {
    // alert("ws://117.78.9.200:8107/notice/" + window.mac + "连接失败");
    console.log(e);
  }
  webSoketSend(data: any) {

    this.webSock.send(data);
    // alert(data);
  }

  //每一分钟发送一次消息
  sendText(){
    if(this.sendTime){
      clearInterval(this.sendTime);
    }
    this.sendTime = setInterval(()=>{
      this.webSoketSend(window.mac);
      console.log('发送padCode消息')
    },60 * 1000)
  }
  reconnect() {
    if (this.lockReconnect) return;
    this.lockReconnect = true;
    let _this = this;
    setTimeout(() => {
      _this.initWebSocket();
      _this.lockReconnect = false;
    }, 2000);
  }

  sendMeg() {
    // console.log("123123123");
    // window.location = `http://send?message=${msg}`;

    if (this.isLog == false) {
      return;
    }
    // alert(window.mac)
    this.webSoketSend(window.mac);
    this.isLog = false;
    setTimeout(() => {
      this.isLog = true;
    }, 60000);

    // return 'js://send?message=11122'
  }

  printTicket(url:any){
    // alert(url)
    axios.get(url).then((res) => {
  showToast('打印小票成功');
})

  }
  sendHttp(msg:any){
    window.location = `http://send?message=${msg}`;
  }

  // 将消息显示在网页上
  setMessageInnerHTML(innerHTML: string) {
    this.mess = innerHTML;
  }

  onSetting() {
    this.$router.push({
      name: "Setting",
      query: { mac: window.mac },
    });
  }

  async getInfo() {
    try {
      const data: any = await getBillLast(window.mac); //this.mac
      const img = data.data.image;
      this.image = img;
      this.billImage = data.data.imageFile;
      this.qr = data.data.qrCode;
      this.amount = data.data.amount;
    } catch (error) {
      // setTimeout(async () => {
      //   this.getInfo(); // 重启
      // }, 5 * 1000);
    }

    setTimeout(async () => {
      this.getInfo(); // 递归
    }, 3 * 1000);
  }

  //获取默认配置
  getConfiguration = async () => {
    const res = await getInfo(window.mac);
    this.isSequencePrint = res.data.isSequencePrint;
  }
}
