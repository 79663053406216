import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

const app = createApp(App);

import 'vant/lib/index.css';

import { NavBar } from 'vant';
app.use(NavBar);

import { Button } from 'vant';
app.use(Button);

import { Col, Row } from 'vant';
app.use(Col);
app.use(Row);

import { Icon } from 'vant';
app.use(Icon);

import { Field, CellGroup} from 'vant';
app.use(Field);
app.use(CellGroup);

import { RadioGroup, Radio } from 'vant';
app.use(Radio);
app.use(RadioGroup);

import { Toast } from 'vant';
app.use(Toast);



app.use(store).use(router).mount('#app')
