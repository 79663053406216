import request from "@/utils/axios";

const prefix = '';

// 获取当前（最后一张）小票 BY 平板MAC
export const getBillLast = ((mac: string) => {
  let time = new Date().getTime();
  return request({
    url: prefix + 'advertising/api/phpPad/getByone?padCode=' + mac + '&t' + time,
    method: 'get',
  })
})

// PAD MAC 跟小链设备绑定
export const bindDevice = ((mac: string, sn: string) => {
  return request({
    url: prefix + 'advertising/api/phpPad/save',
    method: 'post',
    data: {
      padCode: mac,
      equipmentSn: sn,
    }
  })
})

// 保存配置
export const getSetting = ((padCode: string, printType: string, isAppendPrint: string, isSequencePrint: string) => {
  return request({
    url: prefix + 'advertising/api/PhpEquipment/updatePrintXlEquipment',
    method: 'post',
    data:{
      padCode,
      printType,
      isAppendPrint,
      isSequencePrint
    }
  })
})

// 根据padCode获取小链设备管理详细信息
export const getInfo = ((padCode: string) => {
  return request({
    url: prefix + 'advertising/api/PhpEquipment/selectPrintXlEquipment/' + padCode,
    method: 'get',
  })
})
