import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
const isDev = process.env.NODE_ENV == 'development'
const request = axios.create({
  baseURL: 'https://biot.xiaolianguanjia.com',
  // baseURL: 'http://biottest.xiaolianguanjia.com',
  // baseURL: 'http://192.168.0.106:8102',
  // baseURL: 'http://192.168.2.102:8102',
  timeout: 30000,
})
//请求拦截器
request.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers) {
    config.headers.Authorization = localStorage.getItem('token') || '' //sessionStorage
  }
  return config
}, (error: any) => {
  return Promise.reject(error)
})
//响应拦截器
request.interceptors.response.use((response: AxiosResponse) => {
  return response.data
}, (error: any) => {
  return Promise.reject(error)
})
export default request
